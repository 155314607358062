.invoiceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .invoice {
    width: 95%;
    max-width: 800px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .invoiceHeader {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #0077b6;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .logoContainer {
    flex: 1;
  }
  
  .invoiceLogo {
    width: 100px;
    margin-right: 20px;
  }
  
  .invoiceHeaderText {
    flex: 3;
    text-align: left;
    color: #0077b6;
  }
  
  .invoiceTitle {
    text-align: center;
    color: #0077b6;
    margin: 10px 0;
  }
  
  .invoiceDetails {
    margin: 20px 0;
  }
  
  .detailRow {
    display: flex;
    justify-content: space-between;
  }
  
  .invoiceBody {
    margin: 20px 0;
  }
  
  .invoiceTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoiceTable th, .invoiceTable td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .invoiceTable th {
    background-color: #0077b6;
    color: white;
  }
  
  .invoiceFooter {
    text-align: center;
    font-size: 14px;
    color: #555;
    padding-top: 20px;
    border-top: 2px solid #0077b6;
  }
  
  .printButton {
    background-color: #0077b6;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
  }
  
  .printButton:hover {
    background-color: #005f8a;
  }
  